<template>
  <div>
    <h1>Pakkumised </h1>
    <p>Leitud {{ this. count}} pakkumist</p>
    <br>
    <v-data-table
      :headers="headers"
      :items="offers"
      item-key="guid"
      :loading="loading"
      loading-text="Laeb... palun oota"
      :hide-default-footer=true
      :items-per-page="30"
      show-expand
      class="mb-4"
      @click:row="rowClick"
      :expanded.sync="expanded"
    >
      <template v-slot:item.service="{ item }">
        <p v-if="item.service === 'mtpl'">
          Liikluskindlustus
        </p>
        <p v-else-if="item.service === 'mtpl_unregistered'">
          Liikluskindlustus registreerimata sõiduk
        </p>
        <p v-else-if="item.service === 'casco'">
          Kaskokindlustus
        </p>
      </template>
      <template v-slot:item.object="{ item }">
        <template v-if="item.objects.length != 0">
          <strong v-for="object in item.objects">
            {{object.object.licenseplate}} {{object.object.license_plate}} - {{object.object.maker}}
            {{object.object.manufacturer}} {{object.object.model}}
          </strong>
        </template>
        <template v-else>
          <template v-if="item.service === 'mtpl' || item.service === 'casco'">
            Viga päringus
          </template>
          <template v-if="item.service === 'mtpl_unregistered'">
            <strong>{{item.request_data.manufacture}} {{item.request_data.model}}
              ({{item.request_data.year}})</strong>
          </template>
        </template>
      </template>
      <template v-slot:item.policy_guid="{ item }">
        <strong v-if="item.policy_guid">
          Poliisiks sõlmitud
        </strong>
        <p v-else>
          Ootel
        </p>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <v-expand-transition>
          <td :colspan="12">
            <Quotes
              :quotes="item.quotes"
              :offer_id="item.guid"
              v-on:open_log_dialog="openLogDialog"
            ></Quotes>
          </td>
        </v-expand-transition>
      </template>
    </v-data-table>
    <Pagination
      :pagination="this.pagination"
      :page="this.page"
      :searchQuery="this.searchQuery"
      v-on:load_new_page="loadNewPage"
    >
    </Pagination>
    <LogDialog
      :dialog="logDialog"
      :logs="logs"
      v-on:close_log_dialog="closeLogDialog"
    >
    </LogDialog>
  </div>
</template>

<script>
  import axios from "axios";
  import Pagination from '../components/Pagination';
  import Quotes from '../components/Quotes';
  import LogDialog from '../components/LogDialog';

  export default {
    name: "Offers",
    components: { LogDialog, Quotes, Pagination },
    data() {
      return {
        offers: [],
        participant: [],
        loading: true,
        expanded: [],
        headers: [
          { text: 'Toode', value: 'service' },
          { text: 'Objekt', value: 'object' },
          { text: 'Algus', value: 'start_date' },
          { text: 'Esmane arvutus', value: 'created' },
          { text: 'Status', value: 'policy_guid' },
          { text: '', value: 'data-table-expand' }
        ],
        page: 1,
        count: 0,
        pagination: 0,
        logDialog: false,
        logs: []
      }
    },
    mounted() {
      this.fetchOffers();
    },
    watch: {
      '$route.params.search': {
        handler: function () {
          this.searchQuery = this.$route.query.search;
          this.loading = true;
          this.offers = [];
          this.fetchOffers();
        },
        deep: true,
        immediate: true
      },
    },
    methods: {
      fetchOffers: function () {
        this.loading = true;
        this.offers = [];
        let url = axios.defaults.baseURL + '/api/offers' + '?page=' + this.page;
        if (this.searchQuery) {
          url = url + '&query=' + this.searchQuery;
        }
        axios.get(url).then((response) => {
          this.offers = response.data.offers;
          this.count = response.data.count;
          this.pagination = response.data.pages;
          this.loading = false;
        })
      },
      rowClick: function (offer) {
        if (this.expanded.includes(offer)) {
          this.expanded = [];
        } else {
          this.expanded = [];
          this.expanded = [
            offer
          ];
        }
      },
      openLogDialog: function (insurer, offer_id) {
        this.insurer = insurer;
        axios.get(axios.defaults.baseURL + '/api/logs?insurer=' + insurer + '&offer_id=' + offer_id)
        .then((response) => {
          this.logDialog = true;
          this.logs = response.data;
        })
      },
      closeLogDialog: function () {
        this.logDialog = false;
      },
      loadNewPage: function (page) {
        this.page = page;
        this.fetchOffers();
      }
    }
  }
</script>